<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="订单号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
					</a-form-item>

					<!-- <a-form-item label="用户ID" name="userId" class="ui-form__item">
						<a-input v-model:value="formState.userId" placeholder="请输入用户ID"></a-input>
					</a-form-item> -->

					<a-form-item label="用户信息" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入用户信息"></a-input>
					</a-form-item>

					<a-form-item label="商品编码" name="goodsNo" class="ui-form__item">
						<a-input v-model:value="formState.goodsNo" placeholder="请输入商品编码"></a-input>
					</a-form-item>
				</a-row>

				<a-row v-show="showAll">
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear
							style="width: 180px;">
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="物流方式" name="deliveryType" class="ui-form__item">
						<a-select v-model:value="formState.deliveryType" placeholder="请选择物流方式" allow-clear
							style="width: 195px;">
							<a-select-option :value="1">快递</a-select-option>
							<a-select-option :value="2">到店核销</a-select-option>
							<a-select-option :value="3">无</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="支付方式" name="payMessageType" class="ui-form__item">
						<a-select v-model:value="formState.payMessageType" placeholder="请选择支付方式" allow-clear
							style="width: 195px;">
							<a-select-option :value="'wx_lite'">微信支付</a-select-option>
							<a-select-option :value="'alipay'">支付宝支付</a-select-option>
							<a-select-option :value="'card'">一卡通支付</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="购买时间" name="createTime" class="ui-form__item">
						<a-range-picker v-model:value="formState.createTime"></a-range-picker>
					</a-form-item>

					<a-form-item label="核销时间" name="verifyTime" class="ui-form__item">
						<a-range-picker v-model:value="formState.verifyTime"></a-range-picker>
					</a-form-item>

					<a-form-item label="发货时间" name="consignTime" class="ui-form__item">
						<a-range-picker v-model:value="formState.consignTime"></a-range-picker>
					</a-form-item>

					<a-form-item label="收货时间" name="signTime" class="ui-form__item">
						<a-range-picker v-model:value="formState.signTime"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a style="margin-bottom: 10px" @click="showAll = !showAll">
						{{ showAll ? '收起' :'展开' }}
						<Icon v-show="!showAll" icon="DownOutlined"></Icon>
						<Icon v-show="showAll" icon="UpOutlined"></Icon>
					</a>
				</a-row>

				<a-row>
					<a-col :span="18">
						<exportReport type="shopOrderExportStrategy" :searchData="{status: (this.activeKey == 0 ? undefined : this.activeKey),...searchData}"></exportReport>
						<a-button v-permission="['mall_order_goodsOrder_sendManage']" style="margin-right: 10px;"
							@click="showSendManage = true">发货管理</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>

			</a-form>

			<a-tabs style="margin-top: 10px;" v-model:activeKey="activeKey" size="small" type="card"
				@change="onListChange">
				<a-tab-pane key="0" tab="全部"></a-tab-pane>
				<a-tab-pane key="WAIT_BUYER_PAY" tab="待付款"></a-tab-pane>
				<a-tab-pane key="WAIT_SELLER_SEND_GOODS" tab="待发货"></a-tab-pane>
				<a-tab-pane key="WAIT_BUYER_CONFIRM_GOODS" tab="待收货"></a-tab-pane>
				<a-tab-pane key="WAIT_CHECK" tab="待核销"></a-tab-pane>
				<a-tab-pane key="TRADE_FINISH" tab="已完成"></a-tab-pane>
				<a-tab-pane key="CANCEL" tab="已关闭"></a-tab-pane>
			</a-tabs>

			<div>
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:rowSelection="{ type: rowSelectionType, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
					:scroll="{ x: 4000 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'userInfo'">
							<div>用户昵称：{{ record.nickname ? record.nickname : '-' }}</div>
							<div>手机号：{{ record.phone ? record.phone : '-' }}</div>
						</template>
						<template v-if="column.key === 'payTime'">
							{{ transDateTime(record.payTime) }}
						</template>
						<template v-if="column.key === 'orderItemList-goodsTitle'">
							<div v-for="item in record.orderItemList" :key="item.id">
								{{ item.goodsTitle ? item.goodsTitle : '-' }}
							</div>
						</template>
						<template v-if="column.key === 'orderItemList-skuName'">
							<div v-for="item in record.orderItemList" :key="item.id">
								{{ item.skuName ? item.skuName : '-' }}
							</div>
						</template>
						<template v-if="column.key === 'orderItemList-price'">
							<div v-for="item in record.orderItemList" :key="item.id">
								{{ item.price || '-' }}
							</div>
						</template>
						<template v-if="column.key === 'orderItemList-num'">
							<div v-for="item in record.orderItemList" :key="item.id">
								{{ item.num || '-' }}
							</div>
						</template>
						<template v-if="column.key === 'payable'">
							{{ record.payment + record.couponDeduction }}
						</template>
						<template v-if="column.key === 'deliveryType'">
							<div v-if="record.deliveryType === 1">快递配送</div>
							<div v-if="record.deliveryType === 2">到店核销</div>
							<div v-if="record.deliveryType === 3">无</div>
						</template>
						<template v-if="column.key === 'orderVerifier-storeName'">
							{{ record.orderVerifier?.storeName ? record.orderVerifier?.storeName : '-' }}
						</template>
						<template v-if="column.key === 'orderAddress-info'">
							<div v-if="record.deliveryType === 1">
								<div>收货人：{{ record.orderAddress?.name ? record.orderAddress?.name : '-' }}</div>
								<div>联系电话：{{ record.orderAddress?.phone ? record.orderAddress?.phone : '-' }}</div>
								<div>收货地址：{{ record.orderAddress?.address ? record.orderAddress?.address : '-' }}</div>
							</div>
							<div v-else>
								-
							</div>
						</template>
						<template v-if="column.key === 'orderCouponList'">
							<div v-if="record.orderCouponList">
								<div v-for="item in record.orderCouponList" :key="id">{{ item.couponName }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'payMessageType'">
							<div v-if="record.payMessageType === 'wx_lite' || record.payMessageType === 'zero'">微信支付</div>
							<div v-else-if="record.payMessageType === 'alipay'">支付宝支付</div>
							<div v-else-if="record.payMessageType === 'card'">一卡通支付</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === 'SYSTEM_CANCEL'">系统超时取消</div>
							<div v-if="record.status === 'CUSTOMER_CANCEL'">用户自行取消</div>
							<div v-if="record.status === 'WAIT_BUYER_PAY'">等待买家付款</div>
							<div v-if="record.status === 'WAIT_GROUP'">等待成团</div>
							<div v-if="record.status === 'WAIT_SELLER_SEND_GOODS'">等待卖家发货</div>
							<div v-if="record.status === 'WAIT_CHECK'">等待核销</div>
							<div v-if="record.status === 'WAIT_BUYER_CONFIRM_GOODS'">等待买家确认收货</div>
							<div v-if="record.status === 'EXPIRED'">过期</div>
							<div v-if="record.status === 'WAIT_COMMENT'">待评价</div>
							<div v-if="record.status === 'TRADE_FINISH'">订单完成</div>
							<div v-if="record.status === 'AFTERSALE_FINISH'">售后完成</div>
						</template>
						<template v-if="column.key === 'consignTime'">
							{{ transDateTime(record.consignTime) }}
						</template>
						<template v-if="column.key === 'signTime'">
							{{ transDateTime(record.signTime) }}
						</template>
						<template v-if="column.key === 'fees'">
							{{ (record.payment * 0.0054).toFixed(3) }}
						</template>
						<template v-if="column.key === 'predict'">
							{{ (record.payment - (record.payment * 0.0054)).toFixed(3) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']"
								v-if="record.status === 'WAIT_CHECK' || record.status === 'WAIT_SELLER_SEND_GOODS' || record.status === 'WAIT_BUYER_PAY' || record.status === 'WAIT_BUYER_CONFIRM_GOODS'">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['mall_order_goodsOrder_send']" @click="onSend('发货',record)"
											v-if="record.status === 'WAIT_SELLER_SEND_GOODS'">
											<a-menu-item>
												发货
											</a-menu-item>
										</div>
										<div v-permission="['mall_order_goodsOrder_editAddress']"
											@click="onEditAddress(record)"
											v-if="record.status === 'WAIT_SELLER_SEND_GOODS' || record.status === 'WAIT_BUYER_PAY'">
											<a-menu-item>
												修改地址
											</a-menu-item>
										</div>
										<div v-permission="['mall_order_goodsOrder_editLogistics']"
											@click="onEditLogisticsInfo(record)"
											v-if="record.status === 'WAIT_BUYER_CONFIRM_GOODS'">
											<a-menu-item>
												修改物流信息
											</a-menu-item>
										</div>
										<div v-permission="['mall_order_goodsOrder_refund']"
											@click="onShowRefund(record)"
											v-if="record.status === 'WAIT_CHECK' || record.status === 'WAIT_BUYER_CONFIRM_GOODS' || record.status === 'WAIT_SELLER_SEND_GOODS'">
											<a-menu-item>
												退款
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
							<div v-else>-</div>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>

		<a-modal v-model:visible="showSend" :title="title" width="500px" @ok="onSendOk">
			<a-spin :spinning="loading">
				<a-form scrollToFirstError :model="sendModelRef" :labelCol="{span: 8}" :wrapperCol="{span: 16}">
					<a-form-item label="物流公司" name="logisticsCompany"
						:rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-select v-model:value="sendModelRef.logisticsCompany" placeholder="请选择发货公司" allow-clear
							style="width: 195px;">
							<a-select-option :value="'SF'">顺丰</a-select-option>
							<a-select-option :value="'HTKY'">百世</a-select-option>
							<a-select-option :value="'ZTO'">中通</a-select-option>
							<a-select-option :value="'STO'">申通</a-select-option>
							<a-select-option :value="'YTO'">圆通</a-select-option>
							<a-select-option :value="'YD'">韵达</a-select-option>
							<a-select-option :value="'YZPY'">邮政</a-select-option>
							<a-select-option :value="'EMS'">EMS</a-select-option>
							<a-select-option :value="'HHTT'">天天</a-select-option>
							<a-select-option :value="'UC'">优速</a-select-option>
							<a-select-option :value="'DBL'">德邦</a-select-option>
							<a-select-option :value="'JD'">京东</a-select-option>
							<a-select-option :value="'SNWL'">苏宁</a-select-option>
							<a-select-option :value="'SX'">顺心捷达</a-select-option>
							<a-select-option :value="'JTSD'">极兔速递</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="快递单号" name="logisticsNo" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input style="width: 200px;" v-model:value="sendModelRef.logisticsNo"
							placeholder="请输入快递单号"></a-input>
					</a-form-item>

					<a-form-item v-if="this.title !== '发货'" label="订单号" name="orderNo"
						:rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input style="width: 200px;" v-model:value="sendModelRef.orderNo"
							placeholder="请输入订单号"></a-input>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>

		<a-modal v-model:visible="showEditAddress" title="修改地址" width="500px" @ok="onEditAddressOk">
			<a-spin :spinning="loading">
				<a-form scrollToFirstError :model="editAddressModelRef" :labelCol="{span: 8}" :wrapperCol="{span: 16}">
					<a-form-item label="收货人" name="name">
						<a-input style="width: 200px;" v-model:value="editAddressModelRef.name"
							placeholder="请输入收货人"></a-input>
					</a-form-item>

					<a-form-item label="手机号" name="phone">
						<a-input-number style="width: 200px;" :min="0" :precision="0"
							v-model:value="editAddressModelRef.phone" placeholder="请输入订单号"></a-input-number>
					</a-form-item>

					<a-form-item label="收货地址" name="address">
						<a-input style="width: 200px;" v-model:value="editAddressModelRef.address"
							placeholder="请输入收货地址"></a-input>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>

		<a-modal v-model:visible="showEditLogisticsInfo" title="修改物流信息" width="500px" @ok="onEditLogisticsInfoOk">
			<a-spin :spinning="loading">
				<a-form scrollToFirstError :model="editLogisticsInfoModelRef" :labelCol="{span: 8}"
					:wrapperCol="{span: 16}">
					<a-form-item label="物流公司" name="logisticsCompany">
						<a-select v-model:value="editLogisticsInfoModelRef.logisticsCompany" placeholder="请选择发货公司"
							allow-clear style="width: 195px;">
							<a-select-option :value="'SF'">顺丰</a-select-option>
							<a-select-option :value="'HTKY'">百世</a-select-option>
							<a-select-option :value="'ZTO'">中通</a-select-option>
							<a-select-option :value="'STO'">申通</a-select-option>
							<a-select-option :value="'YTO'">圆通</a-select-option>
							<a-select-option :value="'YD'">韵达</a-select-option>
							<a-select-option :value="'YZPY'">邮政</a-select-option>
							<a-select-option :value="'EMS'">EMS</a-select-option>
							<a-select-option :value="'HHTT'">天天</a-select-option>
							<a-select-option :value="'UC'">优速</a-select-option>
							<a-select-option :value="'DBL'">德邦</a-select-option>
							<a-select-option :value="'JD'">京东</a-select-option>
							<a-select-option :value="'SNWL'">苏宁</a-select-option>
							<a-select-option :value="'SX'">顺心捷达</a-select-option>
							<a-select-option :value="'JTSD'">极兔速递</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="快递单号" name="logisticsNo">
						<a-input style="width: 200px;" v-model:value="editLogisticsInfoModelRef.logisticsNo"
							placeholder="请输入快递单号"></a-input>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>

		<a-modal v-model:visible="showImportTemplate" :title="title" width="400px" @ok="onImportTemplateOk">
			<a-spin :spinning="loading">
				<a-form ref="importFrom" name="importFrom" scrollToFirstError :model="importModelRef"
					:labelCol="{span: 6}" :wrapperCol="{span: 18}">
					<a-form-item label="Excel文件" name="fileList" :rules="[{required: true, message: '请上传文件'}]">
						<a-upload v-model:file-list="importModelRef.fileList" list-type="picture"
							action="/admin/ajaxUpload.do" accept=".xlsx" :max-count="1" :beforeUpload="beforeUpload">
							<a-button>
								<Icon icon="UploadOutlined"></Icon>
								上传
							</a-button>
						</a-upload>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>

		<a-modal v-model:visible="showRefund" title="退款" width="810px" @ok="onRefundOk">
			<div>请选择要退款的商品：</div>

			<div style="margin-top: 20px;">
				<a-table :columns="refundColumns" :dataSource="refundList" rowKey="goodsId"
					:rowSelection="{ type: rowSelectionType, selectedRowKeys: selectedRefundRowKeys, onSelect: onSelectRefundChange, onSelectAll: onSelectRefundAll }"
					:scroll="{ x: 500 }">
				</a-table>
			</div>

			<a-row style="margin-top: 20px; color: #c2c2c2">
				<a-col>
					<div>注：</div>
				</a-col>
				<a-col>
					<div>退款商品使用了兑换券，兑换券会一并退回；</div>
					<div>退款商品使用了抵扣券，则需所有商品退款才会退回抵扣券；</div>
					<div>退款商品为卡券商品且用户未进行兑换，卡券将一并回收；</div>
					<div>退款商品为卡券商品且用户已兑换，则卡券不可回收；</div>
				</a-col>
			</a-row>
		</a-modal>

		<a-modal v-model:visible="showSendManage" title="发货管理" width="500px">
			<a-spin :spinning="loading">
				<a-form scrollToFirstError :labelCol="{span: 8}" :wrapperCol="{span: 20}" style="margin-left: 30px;">
					<a-form-item extra="发货导入和物流单导入均需要使用该发货模版">
						<a-button type="link" style="background: rgb(11,173,90); color: white;"
							@click="onExportTemplate">
							发货模板导出
						</a-button>
					</a-form-item>
					<a-form-item extra="使用发货模版导入物流信息，用于批量发货">
						<a-button type="link" style="background: rgb(36,159,126); color: white;"
							@click="onImportTemplate('发货导入')">
							发货导入
						</a-button>
					</a-form-item>
					<a-form-item extra="使用发货模版导入物流信息，用于修改物流信息">
						<a-button type="link" style="background: rgb(36,159,126); color: white;"
							@click="onImportTemplate('物流单导入')">
							物流单导入
						</a-button>
					</a-form-item>
				</a-form>
			</a-spin>
			<template #footer>
				<a-button type="primary" @click="showSendManage = false">确定</a-button>
			</template>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import exportReport from '@/components/exportReport/exportReport.vue';
	import {
		getOrderAfterSalePreview,
		getOrderBaseList,
		importOrderBase,
		importUpdateOrderBase,
		saveOrderAfterSale,
		sendOrderBase,
		updateAddress,
		updateLogistics
	} from "@/service/modules/mall";
	import {
		getCinemaAllList
	} from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
			exportReport
		},
		data() {
			return {
				title: '',
				activeKey: '0',
				showAll: false,
				showSend: false,
				showRefund: false,
				showSendManage: false,
				showImportTemplate: false,
				showEditAddress: false,
				showEditLogisticsInfo: false,
				isEdit: false,
				isSee: false,
				id: 0,
				rowSelectionType: 'checkbox',
				cinemaAllList: [],
				selectedRowKeys: [],
				selectedRefundRowKeys: [],
				loading: false,
				searchData: {},
				formState: {},
				modalRef: {},
				sendModelRef: {},
				importModelRef: {
					fileList: [],
				},
				editAddressModelRef: {},
				editLogisticsInfoModelRef: {},
				columns: [{
					title: '订单号',
					dataIndex: 'orderNo',
				}, {
					title: '用户信息',
					key: 'userInfo',
				}, {
					title: '购买时间',
					key: 'payTime',
				}, {
					title: '订单状态',
					key: 'status',
				}, {
					title: '商品信息',
					key: 'orderItemList-goodsTitle',
				}, {
					title: '规格',
					key: 'orderItemList-skuName',
				}, {
					title: '单价',
					key: 'orderItemList-price',
					width: 100
				}, {
					title: '数量',
					key: 'orderItemList-num',
					width: 100
				}, {
					title: '应付金额',
					key: 'payable',
					width: 100
				}, {
					title: '配送方式',
					key: 'deliveryType',
				}, {
					title: '所属影院',
					key: 'orderVerifier-storeName',
				}, {
					title: '收货信息',
					key: 'orderAddress-info',
				}, {
					title: '运费',
					dataIndex: 'deliveryFee',
					width: 100
				}, {
					title: '优惠方式',
					key: 'orderCouponList',
				}, {
					title: '优惠金额',
					dataIndex: 'couponDeduction',
					width: 100
				}, {
					title: '实付金额',
					dataIndex: 'payment',
					width: 100
				}, {
					title: '微信手续费(0.54%)',
					key: 'fees'
				}, {
					title: '微信预计到账金额',
					key: 'predict'
				}, {
					title: '支付类型',
					key: 'payMessageType',
				}, {
					title: '买家留言',
					dataIndex: 'remark',
				}, {
					title: '核销时间',
					key: 'signTime',
				}, {
					title: '发货时间',
					key: 'consignTime',
				}, {
					title: '收货时间',
					key: 'signTime',
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 150
				}],
				list: [],
				refundColumns: [{
					title: '商品名称',
					dataIndex: 'title',
				}, {
					title: '实付金额',
					dataIndex: 'payment',
				}, {
					title: '退款金额',
					dataIndex: 'refund',
				}],
				refundList: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						if (this.activeKey === '0') {
							this.getData();
						} else {
							this.getData(this.activeKey);
						}
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						if (this.activeKey === '0') {
							this.getData();
						} else {
							this.getData(this.activeKey);
						}
					}
				},
			}
		},
		created() {
			this.onSearch();
			this.getAllCinemaList();
		},
		methods: {
			reset() {
				this.activeKey = '0';
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.activeKey = '0';
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.activeKey === '0') {
					this.getData();
				} else {
					this.getData(this.activeKey);
				}
			},
			async getAllCinemaList() {
				this.cinemaAllList = [];
				let ret = await getCinemaAllList({})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data;
				}
			},
			onShowRefund(item) {
				this.id = item.id;
				this.getOrderAfterSalePreview();
			},
			async getOrderAfterSalePreview() {
				this.loading = true;
				try {
					let ret = await getOrderAfterSalePreview({
						orderId: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						this.refundList = ret.data.orderItemList;
						this.refundList.forEach(item => {
							item.refund = item.payment;
						});
						this.selectedRefundRowKeys = [];
						if (ret.data.orderItemList.length === 1) {
							this.selectedRefundRowKeys.push(ret.data.orderItemList[0].goodsId);
						}
						this.showRefund = true;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onRefundOk() {
				if (this.selectedRefundRowKeys.length === 0) {
					this.$message.error('请选择要退款的订单');
					return;
				}
				let orderItemList = [];
				this.refundList.forEach(item => {
					if (this.selectedRefundRowKeys.includes(item.goodsId)) {
						orderItemList.push({
							orderItemId: item.orderItemId,
							refund: item.refund
						})
					}
				})
				let flag = false;
				orderItemList.forEach(item => {
					if (!item.refund) {
						flag = true;
						return;
					}
				})
				if (flag) {
					this.$message.error('请填写退款金额');
					return;
				}
				this.$confirm({
					title: '提示',
					content: '确认要退款吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await saveOrderAfterSale({
								orderId: this.id,
								orderItemList: orderItemList
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.showRefund = false;
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			async getData(status) {
				if (this.formState.createTime && this.formState.createTime.length === 2) {
					this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
					this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
				}
				if (this.formState.verifyTime && this.formState.verifyTime.length === 2) {
					this.searchData.verifyStartTime = this.moment(this.formState.verifyTime[0].startOf('day')).unix();
					this.searchData.consignEndTime = this.moment(this.formState.verifyTime[1].endOf('day')).unix();
				}
				if (this.formState.consignTime && this.formState.consignTime.length === 2) {
					this.searchData.consignStartTime = this.moment(this.formState.consignTime[0].startOf('day'))
				.unix();
					this.searchData.consignEndTime = this.moment(this.formState.consignTime[1].endOf('day')).unix();
				}
				if (this.formState.signTime && this.formState.signTime.length === 2) {
					this.searchData.signStartTime = this.moment(this.formState.signTime[0].startOf('day')).unix();
					this.searchData.signEndTime = this.moment(this.formState.signTime[1].endOf('day')).unix();
				}
				this.loading = true;
				try {
					let ret = await getOrderBaseList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						status: status,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onSend(title, item) {
				this.sendModelRef = {};
				this.title = title;
				this.showSend = true;
				if (title === '发货') {
					this.sendModelRef = JSON.parse(JSON.stringify(item));
				}
			},
			onEditAddress(item) {
				this.showEditAddress = true;
				this.editAddressModelRef.id = item.id;
				this.editAddressModelRef.name = item.orderAddress.name;
				this.editAddressModelRef.phone = item.orderAddress.phone;
				this.editAddressModelRef.address = item.orderAddress.address;
			},
			onEditLogisticsInfo(item) {
				this.showEditLogisticsInfo = true;
				this.editLogisticsInfoModelRef = JSON.parse(JSON.stringify(item));
			},
			async onSendOk() {
				if (!this.sendModelRef.logisticsCompany) {
					this.$message.error('快递公司不能为空！');
					return;
				}
				if (!this.sendModelRef.logisticsNo) {
					this.$message.error('快递单号不能为空！');
					return;
				}
				this.loading = true;
				try {
					let ret = await sendOrderBase({
						id: this.sendModelRef.id,
						logisticsCompany: this.sendModelRef.logisticsCompany,
						logisticsNo: this.sendModelRef.logisticsNo
					})
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('发货成功！');
						this.activeKey = '0';
						this.showSend = false;
						this.getData();
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onSelectChange(record, selected) {
				if (this.rowSelectionType === 'checkbox') {
					if (selected) {
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onSelectRefundChange(record, selected) {
				if (this.rowSelectionType === 'checkbox') {
					if (selected) {
						this.selectedRefundRowKeys.push(record.goodsId);
					} else {
						this.selectedRefundRowKeys.splice(this.selectedRefundRowKeys.indexOf(record.goodsId), 1);
					}
				}
			},
			onSelectRefundAll(selected) {
				if (selected) {
					this.refundList.forEach(item => {
						if (this.selectedRefundRowKeys.indexOf(item.goodsId) === -1) {
							this.selectedRefundRowKeys.push(item.goodsId);
						}
					})
				} else {
					this.refundList.forEach(item => {
						if (this.selectedRefundRowKeys.indexOf(item.goodsId) !== -1) {
							this.selectedRefundRowKeys.splice(this.selectedRefundRowKeys.indexOf(item.goodsId), 1);
						}
					})
				}
			},
			onListChange() {
				if (this.activeKey === '0') {
					this.getData();
				} else {
					this.getData(this.activeKey);
				}
			},
			async onEditAddressOk() {
				this.loading = true;
				try {
					let ret = await updateAddress({
						orderId: this.editAddressModelRef.id,
						name: this.editAddressModelRef.name,
						phone: this.editAddressModelRef.phone,
						address: this.editAddressModelRef.address,
					})
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.activeKey = '0';
						this.getData();
						this.showEditAddress = false;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async onEditLogisticsInfoOk() {
				this.loading = true;
				try {
					let ret = await updateLogistics({
						orderId: this.editLogisticsInfoModelRef.id,
						logisticsCompany: this.editLogisticsInfoModelRef.logisticsCompany,
						logisticsNo: this.editLogisticsInfoModelRef.logisticsNo,
					})
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.activeKey = '0';
						this.getData();
						this.showEditLogisticsInfo = false;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onExportTemplate() {
        let a = document.createElement('a');
        if (window.location.href.indexOf('dashboard') > -1) {
          a.href = `/dashboard/导入发货模板.xlsx`;
        } else {
          a.href = `/导入发货模板.xlsx`;
        }
				a.download = '发货模板.xlsx';
				a.click();
			},
			onImportTemplate(title) {
				this.title = title;
				this.showImportTemplate = true;
			},
			onImportTemplateOk() {
				if (this.importModelRef.fileList.length === 0) {
					return this.$message.warn('请先上传文件');
				}
				const isLt1M = this.importModelRef.fileList[0].size / 1024 / 1024 < 1;
				if (!isLt1M) {
					return this.$message.error('上传文件太大了, 最大只允许1M');
				}
				this.$refs.importFrom.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret;
						if (this.title === '发货导入') {
							ret = await importOrderBase({}, {
								file: this.importModelRef.fileList[0]
							});
						} else if (this.title === '物流单导入') {
							ret = await importUpdateOrderBase({}, {
								file: this.importModelRef.fileList[0]
							});
						}
						this.loading = false;
						if (ret.code === 200) {
							this.showImportTemplate = false;
							this.importModelRef.fileList = [];
							this.$message.success('操作成功');
							this.getData();
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message.error('上传文件太大了, 最大只允许1M');
				}
				return false;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}

	/* 下划线 */
	:deep(.ant-tabs-ink-bar) {
		background-color: #1890ff;
	}

	/* 选中颜色 */
	:deep(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
		color: #1890ff;
	}
</style>
