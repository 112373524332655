import { send, upload } from "../index";
// 2024-1-8 重新加入
export function saveDoodsLabel(data, opts = {}) {
  //  添加商品标签
  return send({
    url: "/admin/goodsLabel/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateDoodsLabel(data, opts = {}) {
  //  修改商品标签
  return send({
    url: "/admin/goodsLabel/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getGoodsLabelSearchList(data, opts = {}) {
  //  商品标签列表
  return send({
    url: "/admin/goodsLabel/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteGoodsLabel(data, opts = {}) {
  //  删除商品标签
  return send({
    url: "/admin/goodsLabel/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOnShelvesGoodsLabel(data, opts = {}) {
  //  商品标签上架
  return send({
    url: "/admin/goodsLabel/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOffShelvesGoodsLabel(data, opts = {}) {
  //  商品标签下架
  return send({
    url: "/admin/goodsLabel/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getGoodsClassifyList(data, opts = {}) {
  //  商品分类列表
  return send({
    url: "/admin/goodsClassify/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getGoodsAllClassifyList(data, opts = {}) {
  //  全部商品分类列表
  return send({
    url: "/admin/goodsClassify/findAll.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getGoodsClassifyDetail(data, opts = {}) {
  //  商品分类详情
  return send({
    url: "/admin/goodsClassify/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveGoodsClassify(data, opts = {}) {
  //  商品分类添加
  return send({
    url: "/admin/goodsClassify/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateGoodsClassify(data, opts = {}) {
  //  商品分类修改
  return send({
    url: "/admin/goodsClassify/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteGoodsClassify(data, opts = {}) {
  //  商品分类删除
  return send({
    url: "/admin/goodsClassify/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOnShelvesGoodsClassify(data, opts = {}) {
  //  商品分类上架
  return send({
    url: "/admin/goodsClassify/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOffShelvesGoodsClassify(data, opts = {}) {
  //  商品分类下架
  return send({
    url: "/admin/goodsClassify/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getFeeSettingDetail(data, opts = {}) {
  // 运费设置详情
  return send({
    url: "/admin/deliveryFeeSetting/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateFeeSetting(data, opts = {}) {
  // 运费设置更新
  return send({
    url: "/admin/deliveryFeeSetting/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getFreeFeeSettingDetail(data, opts = {}) {
  // 免运费设置详情
  return send({
    url: "/admin/freeDeliveryFeeSetting/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateFreeFeeSetting(data, opts = {}) {
  // 免运费设置更新
  return send({
    url: "/admin/freeDeliveryFeeSetting/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getOrderBaseList(data, opts = {}) {
  // 商城订单列表
  return send({
    url: "/admin/orderBase/shopList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function sendOrderBase(data, opts = {}) {
  // 订单发货
  return send({
    url: "/admin/orderBase/send.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function importOrderBase(data, opts = {}) {
  // 订单导入发货
  return upload({
    url: "/admin/orderBase/import.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function importUpdateOrderBase(data, opts = {}) {
  // 订单导入物流单
  return upload({
    url: "/admin/orderBase/batchUpdateLogistic.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateAddress(data, opts = {}) {
  // 修改收货地址
  return send({
    url: "/admin/orderBase/updateAddress.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateLogistics(data, opts = {}) {
  // 修改物流信息
  return send({
    url: "/admin/orderBase/updateLogistics.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getOrderAfterSalePreview(data, opts = {}) {
  // 商品订单/卖品订单售后预览
  return send({
    url: "/admin/orderAfterSale/preview.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveOrderAfterSale(data, opts = {}) {
  // 商品订单/卖品订单提交售后
  return send({
    url: "/admin/orderAfterSale/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getOrderAfterSaleList(data, opts = {}) {
  // 售后列表
  return send({
    url: "/admin/orderAfterSale/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getOrderAfterSaleDetail(data, opts = {}) {
  // 售后详情
  return send({
    url: "/admin/orderAfterSale/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getOrderTraces(data, opts = {}) {
  // 查询订单物流轨迹信息
  return send({
    url: "/admin/order/orderTraces.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function confirmAuditOrderAfterSale(data, opts = {}) {
  // 售后审核通过(仅退款直接退款，退货退款改成待用户寄出)  →  同意申请
  return send({
    url: "/admin/orderAfterSale/confirmAudit.do",
    method: "POST",
    data,
    ...opts,
  });
}


export function rejectAuditOrderAfterSale(data, opts = {}) {
  // 售后审核拒绝  →  拒绝申请
  return send({
    url: "/admin/orderAfterSale/rejectAudit.do",
    method: "POST",
    data,
    ...opts,
  });
}


export function receiveConfirmOrderAfterSale(data, opts = {}) {
  // 售后确认收货并退款（退货退款）  →  同意退款
  return send({
    url: "/admin/orderAfterSale/receiveConfirm.do",
    method: "POST",
    data,
    ...opts,
  });
}


export function receiveRejectOrderAfterSale(data, opts = {}) {
  // 售后拒绝收货（退货退款）  →  拒绝退款
  return send({
    url: "/admin/orderAfterSale/receiveReject.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getOrderSettingDetail(data, opts = {}) {
  // 订单设置详情
  return send({
    url: "/admin/orderSetting/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateOrderSetting(data, opts = {}) {
  // 订单设置更新
  return send({
    url: "/admin/orderSetting/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getAppSellList(data, opts = {}) {
  // 热卖专场列表
  return send({
    url: "/admin/appSell/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getAppSellDetail(data, opts = {}) {
  // 热卖专场详情
  return send({
    url: "/admin/appSell/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveAppSell(data, opts = {}) {
  // 新增热卖专场
  return send({
    url: "/admin/appSell/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateAppSell(data, opts = {}) {
  // 编辑热卖专场
  return send({
    url: "/admin/appSell/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOnShelvesAppSell(data, opts = {}) {
  // 上架专场活动
  return send({
    url: "/admin/appSell/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOffShelvesAppSell(data, opts = {}) {
  // 下架专场活动
  return send({
    url: "/admin/appSell/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteAppSell(data, opts = {}) {
  // 删除热卖专场
  return send({
    url: "/admin/appSell/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getPointsShopSettingDetail(data, opts = {}) {
  // 积分商城设置详情
  return send({
    url: "/admin/pointsShopSetting/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updatePointsShopSetting(data, opts = {}) {
  // 积分商城设置更新
  return send({
    url: "/admin/pointsShopSetting/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getPlatformHotSearchList(data, opts = {}) {
  // 热搜词列表
  return send({
    url: "/admin/platformHotSearch/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function savePlatformHotSearch(data, opts = {}) {
  // 新增热搜词
  return send({
    url: "/admin/platformHotSearch/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updatePlatformHotSearch(data, opts = {}) {
  // 编辑热搜词
  return send({
    url: "/admin/platformHotSearch/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deletePlatformHotSearch(data, opts = {}) {
  // 删除热搜词
  return send({
    url: "/admin/platformHotSearch/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}